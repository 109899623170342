import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from '../../components/Footer/Footer'
import ReactGA from 'react-ga'
import bvr_512 from '../../assets/bvr512.jpg';
import { FaSteam } from 'react-icons/fa'
import { Link } from "react-router-dom";
import './ps.css'
const BalanceVR = () => {
    ReactGA.pageview('balance-vr')
    return (
        <div className="page">
            <div id="background">
                <NavBar />
                <div className="content" id="productContent">
                    <h1>Balance VR</h1>
                    <br />
                    <img src={bvr_512} width={'360em'} height={'300em'} loading="lazy" alt="product" className="bvr" />
                    <div className="productGrid">
                        <Link to="#">
                            <FaSteam size={36} className='apps' />
                        </Link>
                    </div>
                    <h3>Using the notorious Unreal Engine 4, this Virtual Reality physics-based puzzle game takes a player through various environments and scenes as they try to build a balanced stack!
                        Puzzle games challenge the mind and with each level having different scenes and shapes, every player will find various solutions to these puzzles.
                        Grab each shape given to you and figure out how to stack them on the platform but be careful! You have a limited time to make sure they don't fall once you placed them.
                        If they fall, it's game over. Will you be able to balance your stack?
                    </h3>
                </div>
            </div >
            <Footer />
        </div >
    )
}
export default BalanceVR;
