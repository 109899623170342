import React from "react";
import FeatureItem from "./FeatureItem";
import './features.css'
import jc512 from '../../assets/jc_512.png'
import bvr512 from '../../assets/bvr512.jpg'
import dcsas512 from '../../assets/dcsa_studios_512.png';
const features = [
    {
        image: jc512,
        title: "Jazz Chords",
        description: "A jazz practicing tool to learn scales and their uses in a specific chord for improvisation.",
        path: '/products/jazzchords'
    },
    {
        image: bvr512,
        title: "Balance VR",
        description: "A VR Physics-based Puzzle Game that involves balancing objects on a platform for a specific amount of time.",
        path: '/products/balancevr'
    },
    {
        image: dcsas512,
        title: "DCSA Studios",
        description: "Our Game Development Studio responsible for Balance VR and other games",
        path: '/products/dcsa'
    }
]


const FeatureSection = () => {
    return (
        <div className="page">
            <div id="featureContent">
                <h2 className="blackText">We offer a variety of products and services!</h2>
                <h3 className="description">We mostly specialize in Mobile App development and Video Game Development</h3>
                <h3 className="description">See more of our products and services below</h3>
                <div id="featureGrid">
                    {features.map((item) => <FeatureItem title={item.title} description={item.description} image={item.image} key={item.title} path={item.path} />)}
                </div>
            </div>
        </div>
    )
}
export default FeatureSection;