import React, { useState, useEffect } from "react";
import './navBar.css'
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";



const NavBar = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    return (
        <>
            {isMobile ?
                <NavMobile /> : <NavDesktop />}
        </>
    )
}
export default NavBar;

