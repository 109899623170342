import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from '../../components/Footer/Footer'
import ReactGA from 'react-ga'

const DCSA = () => {
    ReactGA.pageview('dcsa-studios')
    useEffect(() => {
        window.location.href = 'https://www.dcsastudios.com'
    }, [])
    return (
        <div id="privacy" className="page">
            <div id="background">
                <NavBar />

                <div className="content" id="privacyContent">
                    <h1>DCSA Studios</h1>
                    <br />

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default DCSA;
