import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import './careers.css'
import ReactGA from 'react-ga'


const Careers = () => {
    ReactGA.pageview('about')

    const jobs = [
        {
            title: 'Office Assistant',
            quickDetails: 'Assist in office duties such as appointments, paperwork, etc.',
            id: '1'
        },
        {
            title: 'IT Technician',
            quickDetails: 'Manage IT Operations in business, computers, printers, etc.',
            id: '2'
        },
        {
            title: 'Express JS Developer',
            quickDetails: 'Manages and creates backend API for multiple services (Game, Analytics, Authentication, etc)',
            id: '2'
        },
        {
            title: 'UE4 Developer',
            quickDetails: 'BLueprint/C++ Scripting for in game events. ',
            id: '2'
        },
    ]

    return (
        <div className="page">
            <div id="background">
                <NavBar />
                <div className="content" id="careersContent">
                    <h1>Careers</h1>
                    <br />
                    <h2>We currently don't have any openings yet!</h2>
                    <div className="jobGrid">
                        {/* {jobs.map((job) =>
                            <Link to={`/careers/${job.id}`} className=' jobLink'>
                                <div className="jobItem">
                                    <h2 >{job.title}</h2>
                                    <p>{job.quickDetails}</p>
                                </div>
                            </Link>
                        )
                        } */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Careers;