import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from '../../components/Footer/Footer'
import ReactGA from 'react-ga'

const PrivacyPolicy = () => {
    ReactGA.pageview('privacy-policy')
    useEffect(() => {
        window.location.href = 'https://www.termsfeed.com/live/8d28edba-4d55-4280-b83d-f75d5060d9b7'
    }, [])
    return (
        <div id="privacy" className="page">
            <div id="background">
                <NavBar />

                <div className="content" id="privacyContent">
                    <h1>Privacy Policy</h1>
                    <br />

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default PrivacyPolicy;
