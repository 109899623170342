import React from "react";
import { Link } from "react-router-dom";
import logo512 from '../../assets/logo512.png'
import './navBar.css'

export const NavLogo = () => {
    return (
        <Link to="/">
            <img src={logo512} alt="logo" className="navLogo" loading="lazy" />
        </Link>
    )
}

