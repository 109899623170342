import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import ContactForm from './ContactForm'
import './contact.css'
import ReactGA from 'react-ga'
import { Link } from "react-router-dom";
const Contact = () => {
    ReactGA.pageview('contact')
    return (
        <div id="about" className="page">
            <div id="background">
                <NavBar />
                <div className="content" id='contactContent'>
                    <h1>Contact Us</h1>
                    <h3>Talk about your company and some people that work in your company</h3>
                </div>
                <div id="contactContainer">
                    <ContactForm />
                    <div id="infoContainer">
                        <div id="contactInfo">
                            <h3>Address</h3>
                            <h2>1928 N East St, Unit D, Flagstaff, AZ, 86004</h2>
                            <h3>Contact email:</h3>
                            <Link to='mailto:dchavira@dcsoftwareapps.com'><h2>dchavira@dcsoftwareapps.com</h2></Link>
                            <h3>Phone:</h3>
                            <h2>(520) 223-7619</h2>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Contact;