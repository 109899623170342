import React from "react";
import { Link } from "react-router-dom";
import assets from "../../assets/CompanyAssets";
const Footer = ({ logo }) => {
    return (
        <footer>
            <div id="footerLinks">
                <Link className="footerLink" to="/"><h3>Home</h3></Link>
                <Link className="footerLink" to="/careers"><h3>Careers</h3></Link>
                <Link className="footerLink" to="/contact"><h3>Contact</h3></Link>
                <Link className="footerLink" to="/about"><h3>About Us</h3></Link>
                <Link className="footerLink" to="/privacy-policy"><h3>Privacy Policy</h3></Link>
                <Link className="footerLink" to="/dcsa"><h3>DCSA Studios</h3></Link>
            </div>
            <h2>&#169; 2022 {assets.name}</h2>

        </footer>
    )
}
export default Footer;