import React, { useState, useRef } from "react";
import emailjs from 'emailjs-com'
import { init } from '@emailjs/browser';



const ContactForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [service, setService] = useState('Web Services')
    const [message, setMessage] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const form = useRef()
    const handleSubmit = (e) => {
        e.preventDefault()
        setHasSubmitted(true);
        init("user_e4Ec0PboOmWzE6eB6UqHe");
        emailjs.send("service_av2ix4a", "template_eyloyxv",
            {
                to_name: "Damian Chavira",
                from_name: name,
                email: email,
                phone: phone,
                service: service,
                message: message,
                reply_to: email,
            }, "user_e4Ec0PboOmWzE6eB6UqHe");

    }
    return (
        <div id="card">
            <h2 className="blackText">Get Connected</h2>
            <form onSubmit={(e) => handleSubmit(e)} ref={form}>
                <div id="formContainer" className="blackText">
                    <div className="formItem">
                        <label>Your Name</label>
                        <input required type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} />
                    </div>
                    <div className="formItem">
                        <label>Your Email</label>
                        <input required type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                    </div>
                    <div className="formItem">
                        <label>Your Phone Number</label>
                        <input required type="tel" placeholder="XXX-XXX-XXXX" onChange={(e) => setPhone(e.target.value)} value={phone} />
                    </div>
                    <div className="formItem">
                        <label>Service</label>
                        <select required type="" placeholder="Service" onChange={(e) => setService(e.target.value)} value={service}>
                            <option value={'Web Services'}>Web Services</option>
                            <option value={'Jazz Chords'}>Jazz Chords</option>
                            <option value={'DCSA Studios'}>DCSA Studios</option>
                        </select>
                    </div>

                </div>
                <div id="message">
                    <textarea required type="text" id="inquiry" onChange={(e) => setMessage(e.target.value)} value={message} placeholder="Send us an Inquiry here!" />
                </div>
                {!hasSubmitted ?
                    <button type="submit">Send Mail</button> :
                    <button type="button" disabled>Sent!</button>
                }


            </form>
        </div>
    )
}
export default ContactForm