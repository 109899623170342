import React from "react";
import { NavLogo } from "./NavLogo"
import { Link } from "react-router-dom";
import './navBar.css'

const NavDesktop = () => {

    return (
        <nav>
            <div className="navGrid">
                <div id="logo">
                    <NavLogo />
                </div>
                <div className="navList">
                    <Link to="/about" className="navListItem"><h3>About</h3></Link>
                    <Link to="/products" className="navListItem"><h3>Products</h3></Link>
                    <Link to="/careers" className="navListItem"><h3>Careers</h3></Link>
                    <Link to="/contact" className="navListItem"><h3>Contact Us</h3></Link>
                </div>
            </div>
        </nav>
    )
}
export default NavDesktop