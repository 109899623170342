import React from "react";

const Product = ({ name, img, description }) => {
    return (
        <div className="product">
            <img src={img} width={'160em'} loading="lazy" alt="product" className="productPic" />
            <h2>{name}</h2>
            <h3>{description}</h3>
        </div>
    )
}
export default Product;