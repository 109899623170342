import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from '../../components/Footer/Footer'
import ReactGA from 'react-ga'
import jc_512 from '../../assets/jc_512.png';
import { FaGooglePlay, FaAppStore } from 'react-icons/fa'
import { Link } from "react-router-dom";
import './ps.css'
const JazzChords = () => {
    ReactGA.pageview('jazzchords')
    return (
        <div className="page">
            <div id="background">
                <NavBar />
                <div className="content" id="productContent">
                    <img src={jc_512} width={'360em'} loading="lazy" alt="product" className="jazzChords" />
                    <br />
                    <iframe loading="lazy" className="inPageVideoFrame" src="https://www.youtube.com/embed/9-00oDnjA2g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <div className="productGrid">
                        <Link to="#">
                            <FaGooglePlay size={36} className='apps' />
                        </Link>
                        <Link to="#">
                            <FaAppStore size={36} className='apps' />
                        </Link>
                    </div>
                    <h3>Jazz Chords is a Jazz Education Tool to be used by musicians of all levels.
                        It will help give any musician quick information about a chord in a song that they would be practicing on.
                        It will also give the musician different scale choices to use within the given chord.</h3>
                </div>
            </div >
            <Footer />
        </div >
    )
}
export default JazzChords;
