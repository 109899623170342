import React from "react";

const Person = ({ name, profile, image }) => {
    return (
        <div className="person">
            <img src={image} width={'160em'} loading="lazy" alt="person" className="profilePic" />
            <h2>{name}</h2>
            <h3>{profile}</h3>
        </div>
    )
}
export default Person;