import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import bvr from '../../assets/bvr512.jpg'
import studios from '../../assets/dcsa_studios_512.png'
import jc_pic from '../../assets/jc_512.png'
import Product from "./Product";
import './ps.css'
import { Link } from "react-router-dom";
import ReactGA from 'react-ga'

const ProductsServices = () => {
    const products = [
        {
            name: 'Jazz Chords',
            img: jc_pic,
            description: 'A jazz practicing tool to learn scales and their uses in a specific chord for improvisation.',
            path: '/products/jazzchords'
        },
        {
            name: 'Balance VR',
            img: bvr,
            description: 'A VR Physics-based Puzzle Game that involves balancing objects on a platform for a specific amount of time.',
            path: '/products/balancevr'
        },
        {
            name: 'DCSA Studios',
            img: studios,
            description: 'Our Game Development Studio responsible for Balance VR and other games',
            path: '/products/dcsa'
        },
    ]
    ReactGA.pageview('products-services')
    return (
        <div id="about" className="page">
            <div id="background">
                <NavBar />
                <div className="content" id="productContent">
                    <h1>Our Products and Services</h1>
                    <br />
                    <br />
                    <h2>See what we can do for you below</h2>
                    <br />
                    <br />
                    <div id="productGrid">
                        {products.map(
                            (product, i) => <Link to={product.path} className="productLink" key={i}><Product name={product.name} img={product.img} description={product.description} key={product.name} /></Link>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ProductsServices;