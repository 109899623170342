import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AboutPage from './pages/AboutPage/AboutPage';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact/Contact';
import Careers from './pages/Careers/Careers';
import Job from './pages/Careers/Job';
import ProductsServices from './pages/Products_Services/PS';
import PrivacyPolicy from './pages/PrivacyPolicy/PP';
import ReactGa from 'react-ga';
import DCSA from './pages/Products_Services/dcsa';
import JazzChords from './pages/Products_Services/jazzchords';
import BalanceVR from './pages/Products_Services/balancevr';
ReactGa.initialize('UA-213427366-2');

const App = () => {
  return (

    <Router >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="*"
          element={<NotFound />}
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" exactly element={<Job />} />

        {/* Products */}
        <Route path="/products" element={<ProductsServices />} />
        <Route path="/products/dcsa" element={(<DCSA />)} />
        <Route path="/products/jazzchords" element={(<JazzChords />)} />
        <Route path="/products/balancevr" element={(<BalanceVR />)} />

        {/* Privacy Policy */}

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      </Routes>
    </Router >
  );
}

export default App;