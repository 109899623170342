import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import Person from "./Person";
import damian from '../../assets/damian.jpg'
import './about.css'
import ReactGA from 'react-ga'

const AboutPage = () => {
    const employees = [
        {
            name: 'Damian Chavira',
            img: damian,
            profile: 'Founder, CEO.'
        }
    ]
    ReactGA.pageview('about')
    return (
        <div id="about" className="page">
            <div id="background">
                <NavBar />
                <div className="content" id="aboutContent">
                    <h1>About Us</h1>
                    <br />
                    <h2>We are a software development company striving for excellence and high quality products for our consumers</h2>
                    <h3>Founded in 2021, we opened with our app Jazz Chords and our game studios, DCSA Studios.</h3>
                    <br />
                    <br />
                    <h2>Here are some of our key members.</h2>
                    <br />
                    <br />
                    <div id="peopleGrid">
                        {employees.map((e) => <Person name={e.name} image={e.img} profile={e.profile} key={e.name} />)}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default AboutPage;