import React from "react";
import { Link } from "react-router-dom";

const FeatureItem = ({ image, title, description, path }) => {
    return (
        <Link to={path}>
            <div className="blackText featureItem">

                <img src={image} alt="featureLogo" width={'160em'} loading="lazy" />
                <h2>{title}</h2>
                <h3>{description}</h3>
            </div>
        </Link>
    )
}
export default FeatureItem