import React from "react";
import './features.css'

const VideoFeature = () => {

    return (
        <div id="videoContainer">
            <div id="videoFeature" className="blackText">
                <div id="videoDescription">
                    <h2>Jazz Chords</h2>
                    <h3>Jazz Chords is a Jazz Education Tool to be used by musicians of all levels.</h3>
                    <h3>Want to see more? Click the button below!
                    </h3>
                    <button type="button" onClick={() => window.location.href = 'https://www.dcsoftwareapps.com/products/jazzchords'}>Jazz Chords</button>

                </div>
                <div id="video">
                    <iframe loading="lazy" className="videoFrame" src="https://www.youtube.com/embed/9-00oDnjA2g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            <hr />
            <div id="videoHighlights" className="blackText">
                <div className="highlight">
                    <h2>iOS and Android</h2>
                    <h3>CHeck us out on the App Store or the Play Store!</h3>
                </div>
                <div className="highlight">
                    <h2>Constant Updates</h2>
                    <h3>Jazz theory is ever growing and so is the knowledge being shared, we are constantly adding new chords and scales.</h3>
                </div>
                <div className="highlight">
                    <h2>Quick Support</h2>
                    <h3>Having issues with our app? Email us at <a href='mailto:contact@dcsoftwareapps.com' className="inTextLink">contact@dcsoftwareapps.com</a></h3>
                </div>
            </div>
        </div>
    )
}
export default VideoFeature;