import React from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

const Job = ({ title, description, id }) => {
    return (
        <div className="page">
            <div id="background">
                <NavBar />
                <div className="content">
                    <h1>{title}</h1>

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Job