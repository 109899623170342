import React, { useState } from "react";

import { MdMenu, MdClose } from "react-icons/md";
import { NavLogo } from './NavLogo'
import './navBar.css'
import { Link } from "react-router-dom";
const NavMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav>
            {!isOpen ? (
                <div className="navGrid">
                    <div id="menu">
                        <MdMenu size={36} color={'white'} onClick={() => setIsOpen(true)} className="navButton" />
                    </div>
                    <div id="logo">
                        <NavLogo />
                    </div>
                </div>) : (

                <div className="navList">
                    <MdClose size={36} color={'black'} onClick={() => setIsOpen(false)} className="navButton" />

                    <Link to="/" className="navListItem"><h3>Home</h3></Link>
                    <Link to="/about" className="navListItem"><h3>About</h3></Link>
                    <Link to="/products" className="navListItem"><h3>Products/Services</h3></Link>
                    <Link to="/careers" className="navListItem"><h3>Careers</h3></Link>
                    <Link to="/contact" className="navListItem"><h3>Contact Us</h3></Link>
                </div>
            )}
        </nav>
    )
}
export default NavMobile;