import React from "react";
import FeatureSection from "../../components/Features/FeatureSection";
import VideoFeature from "../../components/Features/VideoFeature";
import NavBar from '../../components/NavBar/NavBar'
import Footer from "../../components/Footer/Footer";
import productImage from '../../assets/products/jcScreens.png'
import { Link } from "react-router-dom";
import { GiVrHeadset } from 'react-icons/gi'
import ReactGA from 'react-ga'
import { MdSmartphone, MdWeb } from 'react-icons/md'
import './home.css'
const HomePage = ({ logo }) => {

    ReactGA.pageview('home')
    return (
        <div id="home" className="page">
            <div id="background">
                <NavBar />
                <div className="content">
                    <div className="homeGrid">
                        <div>
                            <h2>
                                From software and mobile applications to video games,
                                here at DC Software Apps LLC, we cover a large variety of products!
                                Check out our app and other products.
                            </h2>
                        </div>
                        <div className="featureProductContainer">
                            <img src={productImage} alt='Product' className="featureProductImage" />
                        </div>

                    </div>
                    <div className="cores">
                        <div className="coreItem">
                            <Link to='/products/jazzchords'>
                                <div className="coreDetails">
                                    <MdSmartphone color={"white"} size={36} />
                                    <p>Jazz Chords and custom mobile applications</p>
                                </div>
                            </Link>
                        </div>
                        <div className="coreItem">
                            <Link to='/products/balancevr'>
                                <div className="coreDetails">
                                    <GiVrHeadset color={"white"} size={36} />
                                    <p>BalanceVR and more VR games coming soon!</p>
                                </div>
                            </Link>
                        </div>
                        <div className="coreItem">
                            <Link to='/contact'>
                                <div className="coreDetails">
                                    <MdWeb color={"white"} size={36} />
                                    <p>Contact us for more services!</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <FeatureSection />
            <VideoFeature />
            <Footer />
        </div>
    );
}
export default HomePage;