import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from '../components/Footer/Footer'

const NotFound = () => {
    return (
        <div id="notFound" className="page">
            <div id="background" className="notFound">
                <NavBar />
                <div className="content">
                    <h1>Sorry, this page isn't found!</h1>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default NotFound;